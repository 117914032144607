import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "./HomePage.css";
import sharediskSmall from "../assets/sharedisk-small-min.png";
import arrowRight from "../assets/arrow-right.png";

import facebook from "../assets/facebooklarge-min.png";
import twitter from "../assets/twitterlarge-min.png";
import youtube from "../assets/youtubelarge-min.png";
import telegram from "../assets/telegram.svg";

export default function HomePage() {
  return (
    <div>
      <NavBar />

      <div className="main-flex">
        <img
          className="main-img"
          src="https://i.ibb.co/2y5KNrK/homenew-min.png"
          alt=""
        />
        <div className="column-flex">
          <div className="info-line-mobile">
            World fastest cloud based video sharing platform ever created with
            AI
          </div>
          <div className="main-heading">
            Share And Save Videos Anywhere in The World Free, Fast & Easy
          </div>
          <div className="info-line-1 homepage">
            World fastest cloud based video sharing platform ever created with
            AI
          </div>

        <div className="socials mobile">
          <a target="_blank" href="https://www.facebook.com/sharedisk/">
          <img src={facebook} alt="" />
          </a>
          <a target="_blank" href="https://twitter.com/sharedisk">
          <img src={twitter} alt="" />
          </a>
          <a target="_blank" href="https://www.youtube.com/@sharedisk">
          <img src={youtube} alt="" />
          </a>
          <a target="_blank" href="https://t.me/sharediskofficial">
            <img src={telegram} alt="" />
          </a>
        </div>
        <Link style={{textDecoration:"none"}} className="join-link" to="/creator-program" >
          <div className="info-line-2">
            <img src={sharediskSmall} className="sharedisk-small-img" alt="" />
            Join Creator Program
            <img className="arrow-right" src={arrowRight} alt="" />
            <div className="betatag">Beta</div>
          </div>
</Link>
        </div>
      </div>

      <Footer />
    </div>
  );
}
